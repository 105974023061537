import { template as template_02cb9c64d9824f0fa1e29bc15268e7c1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_02cb9c64d9824f0fa1e29bc15268e7c1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
