import { template as template_bcae4bf8b0204448bb4c4cc39ed402cf } from "@ember/template-compiler";
const WelcomeHeader = template_bcae4bf8b0204448bb4c4cc39ed402cf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
