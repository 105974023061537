import { template as template_7c0953987c204546a374fa00f848dc15 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7c0953987c204546a374fa00f848dc15(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
